<template>
    <BaseLayout>
        <template v-slot:page-content>
            <div class="container-fluid">
                <div class="mr-custom mt-5 ml-custom"  v-if="!loading">
                    <div class="row">

                        <!--=== Product Gallery  ===-->
                        <div class="col-lg-4 col-12">
                            <ProductGallery :gallery="product.Media"/>
                        </div>

                        <div class="col-lg-6 col-12">
                            <div class="product-description">
                                <h1>{{ product.name }}</h1>
                                <div class="product-meta">
                                    <div class="Stars" :style="{'--rating': this.product.rate}"
                                         :aria-label="'Rating of this product is ' + product.rate + ' out of 5'"></div>
                                    <!--                                    <p>180 X 200 CM</p>-->
                                    <p>{{ product.product_no }}</p>
                                    <p>{{ product.quality_name }}</p>
                                </div>

                                <p>
                                    {{ product.description }}
                                </p>

                                <form class="order-form">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group mt-2">
                                                <label>{{ $t('order.size') }}</label>
                                                <select id="size" class="form-control" v-model="size">
                                                    <option v-for="(product_size,index) in product.size_price"
                                                            :selected="index === 0" :value="product_size.size"
                                                            :key="product_size.id">{{ product_size.size }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-6 align-self-end">
                                            <p class="total-price">
                                                {{ price }}
                                                {{currency.coin.name}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-12">
                                            <div class="form-group mt-2">
                                                <label for="quantity">{{ $t('order.quantity') }}</label>
                                                <div class="counter">
                                                    <button class="btn btn-gray btn-counter" type="button"
                                                            @click="decreaseCounter" :disabled="quantity === 1">
                                                        <span class="sr-only">decrease counter by 1</span>
                                                        <i class="fas fa-minus"></i>
                                                    </button>
                                                    <input type="number" v-model="quantity" min="1" class="form-control" id="quantity">
                                                    <button class="btn btn-gray btn-counter" type="button"
                                                            @click="quantity+=1">
                                                        <span class="sr-only">increase counter by 1</span>
                                                        <i class="fas fa-plus"></i>
                                                    </button>

                                                    <p class="total-price">
                                                        {{ Math.round(price * quantity) }}
                                                         {{ currency.coin.name}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-wrap gap-2 mt-2">
                                        <button class="btn mb-lg-0 mb-2 btn-primary btn-order btn-add-to-cart" type="button"
                                                @click="addToCart" :disabled="add_in_progress || added">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17.984" height="18.159"
                                                 viewBox="0 0 17.984 18.159" v-if="!added && !add_in_progress">
                                                <path id="shopping-cart-2"
                                                      d="M17.554,3.446a1.765,1.765,0,0,0-1.351-.6H3.277l-.13-.975A1.973,1.973,0,0,0,1.482,0H.532a.532.532,0,1,0,0,1.064h.95c.171,0,.532.361.611.95L3.615,13.436a2.151,2.151,0,0,0,.728,1.327,2.2,2.2,0,0,0,1.021.508,1.957,1.957,0,1,0,3.461.039h2.788a1.957,1.957,0,1,0,3.481,0h1.394a.532.532,0,0,0,0-1.064H5.756A1.142,1.142,0,0,1,4.67,13.3l-.111-.835h10.5a2.209,2.209,0,0,0,2.14-1.874l.763-5.723A1.765,1.765,0,0,0,17.554,3.446ZM7.978,16.2a.893.893,0,1,1-.893-.893A.894.894,0,0,1,7.978,16.2Zm6.268,0a.893.893,0,1,1-.893-.893A.894.894,0,0,1,14.246,16.2ZM16.753,4.147a.715.715,0,0,1,.158.576l-.32,2.4h-3.62l.214-3.21H16.2A.716.716,0,0,1,16.753,4.147ZM9.2,11.4l-.214-3.21h2.851l-.214,3.21ZM8.913,7.123,8.7,3.913h3.42l-.214,3.21Zm-1.28-3.21.214,3.21h-4l-.428-3.21ZM3.989,8.187H7.918l.214,3.21H4.417ZM15.063,11.4H12.687l.214-3.21H16.45l-.3,2.259A1.142,1.142,0,0,1,15.063,11.4Zm0,0"
                                                      fill="#fff"/>
                                            </svg>
                                            <span class="spinner-border in_progress text-light" role="status"
                                                  v-if="add_in_progress">
                                                <span class="sr-only">Loading...</span>
                                            </span>
                                            <i class="far fa-check-circle added" v-if="added"></i>
                                            <span>{{ added ? $t('product.added-to-cart') : $t('product.add-to-cart') }}</span>
                                        </button>

                                        <button class="btn btn-gray btn-fav position-relative" type="button" @click="toggleFavorite" :disabled="load_fav">
                                            <i class="fas fa-heart" :class="{'is_favorite' : is_fav}"></i>
                                            <span class="spinner-grow  bg-white position-absolute"  v-if="load_fav"  role="status">
                                                <span class="sr-only">Loading...</span>
                                            </span>
                                        </button>


                                        <button class="btn btn-gray btn-share" type="button" data-toggle="modal"
                                                data-target="#shareModal">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16.351" height="16.351"
                                                 viewBox="0 0 16.351 16.351">
                                                <path id="share"
                                                      d="M8.468,9.49a3.3,3.3,0,0,1,0,1.37l4.146,2.073a3.258,3.258,0,1,1-.732,1.462L7.737,12.323a3.27,3.27,0,1,1,0-4.294l4.146-2.073a3.272,3.272,0,1,1,.732,1.462Zm-3.2,2.32a1.635,1.635,0,1,0-1.635-1.635A1.635,1.635,0,0,0,5.27,11.81Zm9.81-4.905A1.635,1.635,0,1,0,13.445,5.27,1.635,1.635,0,0,0,15.08,6.905Zm0,9.81a1.635,1.635,0,1,0-1.635-1.635A1.635,1.635,0,0,0,15.08,16.716Z"
                                                      transform="translate(-2 -2)" fill="#0e5aa6" fill-rule="evenodd"/>
                                            </svg>
                                            <span>{{ $t('product.share') }}</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-7 col-12">
                            <div class="questions" >
                                <details class="answer" v-for="attr in product.attributes" :key="attr.id">
                                    <summary class="question">
                                        <span class="q">
                                                {{ attr.name }}
                                        </span>
                                        <span class="arrow">
                                            <i class="fas fa-plus"></i>
                                        </span>
                                    </summary>

                                    <div>
                                        <p class=" fade-in">
                                            {{ attr.description }}
                                        </p>
                                        <div v-if="attr.image?.slice(attr.image.lastIndexOf('.')) === '.mp4'">
                                            <video class="d-block attr-image img-gallery" controls>
                                                <source :src="attr.image" type="video/mp4">
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                        <div v-else>
                                            <img :src="attr.image" class="d-block attr-image img-gallery" alt="">
                                        </div>
                                    </div>



                                </details>
                            </div>
                        </div>
                    </div>
                </div>
                <Loader v-else/>
            </div>



            <div class="all" style="margin-bottom: 10rem;"  v-if="!loading && product.similar">
                <ProductSlider :sub-header="$t('product.similar-products')" swiper-ref="similarProducts"/>
            </div>


            <!-- Modal -->
            <div class="modal fade" id="shareModal" data-backdrop="static" data-keyboard="false" tabindex="-1"
                 aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">{{ product.name }}</h5>
                            <button type="button" class="close p-0 m-0" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="copy">
                                <form>
                                    <input type="text" ref="page_url" class="form-control link-input" :value="url"
                                           readonly>
                                    <button type="button" class="copy-btn btn" @click="performCopy">Copy</button>
                                </form>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </template>
    </BaseLayout>
</template>

<script>
import BaseLayout from "@/containers/BaseLayout";
import ProductGallery from "@/views/Product/components/ProductGallery";
import ProductSlider from "@/views/Home/components/ProductSlider";
import {useToast} from "vue-toastification";
import {showProduct, toggle_favorite} from "@/services/products";
import {addToCart} from "@/services/cart";
import store from "@/store";
import {mapGetters} from "vuex";
import Loader from "@/components/Loader";

export default {
    name: "Product",
    components: {ProductSlider, ProductGallery, BaseLayout,Loader},
    data() {
        return {
            loading:false,
            toast: useToast(),
            quantity: 1,
            is_fav: false,
            product: {},
            size: '',
            price:'',
            url: '',
            add_in_progress: false,
            added: false,
            load_fav: false
        }
    },
    watch: {
        size : function () {
            const selectedSize = this.product.size_price.find(item=>item.size === this.size)
            this.price = selectedSize.price
        }
    },
    computed: {
        ...mapGetters({
            countries: "countries",
            currency: "getCurrency",
        })
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    mounted() {
        this.url = window.location.href;

        this.fetchProduct();
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        checkIfFav() {
            let favorites = store.getters.favoriteProducts
            let idx = favorites.findIndex(product => product.id === this.product.id);
            this.is_fav = idx !== -1;
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        fetchProduct() {
            this.loading = true;
            let product_id = this.$route.params.slug
            if (product_id) {
                showProduct(product_id).then(res => {
                    this.product = res.data.Product;
                    this.size = res.data.Product.size_price[0].size;
                    document.title = res.data.Product.name;
                    this.checkIfFav();
                    this.loading = false;
                })
            }

        },
        decreaseCounter() {
            if (this.quantity > 1) {
                this.quantity -= 1
            }
        },
        performCopy() {
            let textToCopy = this.$refs.page_url;
            textToCopy.select();
            textToCopy.focus();
            document.execCommand('copy');
            this.toast.success('Copied Successfully', {timeout: 1500, closeButton: false,})
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        addToCart() {
            if(store.getters.user.loggedIn) {
                let data = {
                    product_id: this.product.id,
                    quantity: this.quantity,
                    size: this.size
                }
                this.add_in_progress = true
                addToCart(data).then(res => {
                    this.add_in_progress = false;
                    this.added = true
                    this.quantity = 1
                    store.dispatch('addProductToCart', res.data.Cart)
                    setTimeout(() => this.added = false, 2000)
                })
            }else {
                this.$router.push('/login')
            }


        },
        toggleFavorite() {
            if(store.getters.user.loggedIn) {
                this.load_fav = true;
                toggle_favorite({"product_id": String(this.product.id)})
                    .then(res => {
                        this.load_fav = false
                        this.is_fav = res.data.Product.is_favorite;
                        store.dispatch("toggleFav", res.data.Product);
                    })
            }else {
                this.$router.push('/login')
            }
        }
    }
}
</script>

<style scoped>

@media (min-width: 992px) {
    #shareModal .modal-dialog {
        max-width: 600px;
    }
}

.copy {
    display: flex;
    padding-right: 10px;
}


form {
    position: relative;
    width: 100%;
}

form .link-input {
    display: block;
    width: 100%;
    border: 3px solid;
    outline: 0;
    background: #FFF;
    font-size: 25px;
    padding: 5px 110px 5px 4px;
    text-align: left;
}

form .copy-btn {
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border: 0;
    outline: 0;
    color: #FFF;
    background: #000;
    font-family: "VT323", monospace;
    font-size: 25px;
    text-transform: uppercase;
    padding: 0.08em 0.8em;
    cursor: pointer;
    width: 100px;
}

.btn-add-to-cart {
    min-width: 160px;
}

.in_progress,
.added {
    font-size: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    margin-inline-end: 8px;
}

.added {
    vertical-align: middle;
}

.fade-in {
    animation: fadeIn ease-in 900ms;
    -webkit-animation: fadeIn ease-in 900ms;
    -moz-animation: fadeIn ease-in 900ms;
    -o-animation: fadeIn ease-in 900ms;
}

.attr-image {
    width: 300px;
    height: 200px;
    max-width: 100%;
    border-radius: 8px;
}
@keyframes fadeIn{
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
</style>