<template>
    <div id="productSlider" class="carousel slide" data-ride="carousel">

        <div class="carousel-inner">

            <div class="carousel-item" :class="{'active': index === 0}" v-for="(image,index) in gallery" :key="image.id">
                <div v-if="image.file.slice(image.file.lastIndexOf('.')) === '.mp4'">
                    <video class="d-block w-100 img-gallery" controls>
                        <source :src="image.file" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div v-else>
                    <img :src="image.file" class="d-block w-100 img-gallery" alt="">
                </div>
            </div>


        </div>

        <div class="slider_controls">
            <a class="carousel-control-prev" href="#productSlider" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#productSlider" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>

        <ol class="carousel-indicators" dir="rtl" style="padding-right: 0;">
            <li v-for="(image,index) in gallery" :key="image.id" data-target="#productSlider" :data-slide-to="index"
                :class="{'active': image.id === 1}">
                <div v-if="image.file.slice(image.file.lastIndexOf('.')) === '.mp4'">
                    <video class="thumbnail border" width="100" height="80" muted>
                        <source :src="image.file" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div v-else>
                    <img :src="image.file" class="thumbnail" alt="">
                </div>

            </li>

        </ol>
    </div>
</template>

<script>
export default {
    name: "ProductGallery",
    props: ["gallery"],

}
</script>

<style>

#productSlider img {
    border-radius: 6px;
    object-fit: cover;
    object-position: center;
}

#productSlider .carousel-item img,
#productSlider .carousel-item  video{
    max-width: 471px;
    height: 400px;
    object-fit: contain;
}

#productSlider .carousel-indicators {
    margin-right: 0;
    margin-left: 0;
    position: relative;
    justify-content: flex-start;
    max-width: 471px;
    overflow-x: auto;
}

#productSlider .carousel-indicators li {
    min-width: 100px;
    opacity: .8;
    height: 80px;
}

#productSlider .carousel-indicators li:not(:first-of-type) {
    margin-right: .34rem;
}

#productSlider .carousel-indicators li.active {
    opacity: 1;
}

#productSlider .carousel-indicators li img {
    background-size: cover;
    height: 85px;
    width: 100px;
    border: 1px solid #cfcaca;
}

#productSlider .carousel-indicators .active img,
#productSlider .carousel-indicators .active video{
    box-shadow: 0 6px 10px rgba(0, 0, 0, .26);
}

.slider_controls {
    display: flex;
}

.slider_controls .carousel-control-next,
.slider_controls .carousel-control-prev {
    top: unset;
    bottom: 1.75rem;
    right: -3.5rem;
}

.slider_controls .carousel-control-next {
    bottom: 3.25rem;
}

.slider_controls .carousel-control-prev-icon {
    background-image: url("../../../assets/img/prev.svg");
}

.slider_controls .carousel-control-next-icon {
    background-image: url("../../../assets/img/next.svg");
}

.img-gallery {
    height: 347px;
    object-position: center;
    object-fit: cover;
}

@media (max-width: 992px) {
    #productSlider .carousel-item img {
        margin: 0 auto;
    }

    #productSlider .carousel-indicators {
        max-width: unset;
        justify-content: center;
    }

    .slider_controls .carousel-control-next, .slider_controls .carousel-control-prev {
        right: 7.5rem;
    }

}

@media (max-width: 840px) {
    .slider_controls .carousel-control-next, .slider_controls .carousel-control-prev {
        top: 0;
        bottom: 0;
    }

    .slider_controls .carousel-control-prev-icon,
    .slider_controls .carousel-control-next-icon {
        background-color: #fff;
        border-radius: 50%;
    }

    .slider_controls .carousel-control-next {
        right: 0;
    }

    .slider_controls .carousel-control-prev {
        left: 0;

        right: unset;
    }
}

@media (max-width: 568px) {
    #productSlider .carousel-indicators li {
        min-width: 80px;
        height: 80px;
    }
}

</style>